.form-control {
  &[type='file'] {
    &::-webkit-file-upload-button {
      background-color: transparent;
    }
  }
  &:hover {
    &:not(:disabled):not([readonly])::-webkit-file-upload-button {
      background-color: transparent;
    }
  }
}
