// Select

.select-input {
  &.form-control {
    &[readonly]:not([disabled]) {
      background-color: transparent;
    }
  }
}

.form-select {
  transition: $form-select-transition;
}

.form-select:focus {
  border-color: $form-select-focus-border-color;
  outline: 0;
  box-shadow: $form-select-focus-border-box-shadow;
}
