// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
//MDB
@import "vendor/mdb/scss/mdb.pro";

body {
    background-color: #fff;
    color: #000;
}

body.dark-theme {
    background-color: #262626;
    color: #fff;
}
.dropdown-menu-large {
    width: 770px;
}
#card-custom {
    width: 350px;
    height: 300px;
    background: hsla(0, 0%, 100%, 0.15);
    backdrop-filter: blur(30px);
}

#shape-1,
#shape-2 {
    min-width: 400px;
    height: 400px;
    filter: blur(24px);
}

#shape-1 {
    background-color: hsl(211, 100%, 24%);
    left: -10px;
}
#shape-2 {
    background-color: hsl(213, 100%, 15%);
    right: 20px;
}

.scale-up-center, .scale-up-center2 {
    animation: scale-up-center 7s cubic-bezier(0.39, 0.575, 0.565, 1)
    infinite both;
}

.companyColor{
    color: #003B78;
}

.bgAlternate{
    background-color: #003B78;
    color: #fff;

    .card {
        color: #000;
    }
}

p, li{
    font-size: 14pt;
}

.text-justify{
    text-align: justify;
}



@keyframes scale-up-center {
    0% {
        transform: translate(0px, 0px) scale(1);
    }
    33% {
        transform: translate(30px, -50px) scale(1.1);
    }
    66% {
        transform: translate(-20px, 20px) scale(0.9);
    }
    100% {
        transform: translate(0px, 0px) scale(1);
    }
}
@keyframes scale-up-center2 {
    0% {
        transform: translate(0px, 0px) scale(1);
    }
    33% {
        transform: translate(-20px, 60px) scale(1.1);
    }
    66% {
        transform: translate(50px, -10px) scale(0.9);
    }
    100% {
        transform: translate(0px, 0px) scale(1);
    }
}
@media (min-width: 451px) and (max-width: 991px) {
    .dropdown-menu-large {
        width: 600px;
    }
}

@media (max-width: 450px) {
    .dropdown-menu-large {
        width: 290px;
    }
}
.page-header {
    padding: 0;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: 50%;
}
.oblique {
    transform: skewX(-10deg);
    overflow: hidden;
    width: 60%;
    right: -10rem;
    border-bottom-left-radius: 0.75rem;
}
.img-oblique {
    transform: skewX(10deg);
}
.img-settings {
    image-rendering: pixelated;
    filter: brightness(110%) contrast(110%) saturate(100%);
}
.fw-900 {
    font-weight: 900;
}
.no-webp body #kompatibel #footerContact {
    background-image: url('/img/footer/contact-us.jpg');
    background-position: center;
    background-repeat: no-repeat;
}
.webp body #kompatibel #footerContact {
    background-image: url('/img/footer/contact-us.webp');
    background-position: center;
    background-repeat: no-repeat;
}

.no-webp body #kompatibel #contactHeader {
    background-image: url('/img/contact/telephone.jpg');
    background-position: center;
    background-repeat: no-repeat;
}
.webp body #kompatibel #contactHeader {
    background-image: url('/img/contact/telephone.webp');
    background-position: center;
    background-repeat: no-repeat;
}
.no-webp body #kompatibel #appHeader {
    background-image: url('/img/leistungen/ux-g3267fe2a5_1920.jpg');
    background-position: center;
    background-repeat: no-repeat;
}
.webp body #kompatibel #appHeader {
    background-image: url('/img/leistungen/ux-g3267fe2a5_1920.webp');
    background-position: center;
    background-repeat: no-repeat;
}
.no-webp body #kompatibel #softwareHeader {
    background-image: url('/img/leistungen/technology-gfc2c987ae_1920.jpg');
    background-position: center;
    background-repeat: no-repeat;
}
.webp body #kompatibel #softwareHeader {
    background-image: url('/img/leistungen/technology-gfc2c987ae_1920.webp');
    background-position: center;
    background-repeat: no-repeat;
}
.no-webp body #kompatibel #serviceVorOrtHeader {
    background-image: url('/img/ueber-uns/server.png');
    background-position: center;
    background-repeat: no-repeat;
}
.webp body #kompatibel #serviceVorOrtHeader {
    background-image: url('/img/ueber-uns/server.webp');
    background-position: center;
    background-repeat: no-repeat;
}
.no-webp body #kompatibel #webdesignHeader {
    background-image: url('/img/leistungen/website-g4f8019516_1920.jpg');
    background-position: center;
    background-repeat: no-repeat;
}
.webp body #kompatibel #webdesignHeader {
    background-image: url('/img/leistungen/website-g4f8019516_1920.webp');
    background-position: center;
    background-repeat: no-repeat;
}
